body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.h5-card {
  font-size: 1em;
}

.col-tight {
  padding: 5px !important;
}

.card {
  border: 0 !important;
  border-radius: 1rem !important;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.card .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card .card-body {
  padding: 2rem;
}

.card-note {
  width: 100%;
  display: block;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgb(0 0 0 / 10%);
}

@media (min-width: 1800px) {
  .container-95 {
    max-width: 1700px imp !important;
    margin: 50px;
  }
}

@media (min-width: 1200px) {
  .container-95 {
    max-width: 1100px;
    margin: 25px;
  }
}

.no-flex {
  display: block !important;
}

.no-flex-50 {
  display: block !important;
  max-width: 50% !important;
  text-align: center !important;
  justify-content: center !important;
}

.inline {
  display: inline !important;
  text-align: center !important;
  justify-content: center !important;
}

.form-compact {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  margin-bottom: 0px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.display-none {
  display: none !important;
}

.display-offscreen {
  position: absolute !important;
  left: -1000px !important;
  top: -1000px !important;
}

.autocomplete-flex {
  display: inline-flex !important;
}

.mw-3 {
  min-width: 3em !important;
}
.mw-5 {
  min-width: 5em !important;
}
.mw-7 {
  min-width: 7em !important;
}

.tf-3 {
  width: 3em !important;
}

.tf-4 {
  width: 4em !important;
}

.tf-5 {
  width: 5em !important;
}

.tf-7 {
  width: 7em !important;
}

.tf-10 {
  width: 10em !important;
}

.input-narrow {
  width: 65px !important;
  height: 50px !important;
  font-size: 36px !important;
}

.image-oversize-100 {
  width: 100%;
  height: auto;
}

.image-oversize-75 {
  width: 75%;
  height: auto;
}

.image-oversize-50 {
  width: 50%;
  height: auto;
}

.font-size-override-125 {
  font-size: 1.25rem !important;
}

.font-size-override-1 {
  font-size: 1rem !important;
}

.font-size-override-75 {
  font-size: 0.75em !important;
}

.form-check-input:checked {
  background-color: #198754 !important;
  border-color: #198754 !important;
}

.del {
  text-decoration: line-through;
}

.fa-theme {
  --fa-primary-opacity: 0.8;
  --fa-secondary-opacity: 0.45;
  --fa-primary-color: rgb(2, 2, 2);
  --fa-secondary-color: rgb(128, 128, 128);
}

.clean-list-group {
  outline: none !important;
  max-width: 500px !important;
}

.scrollTop {
  position: fixed;
  width: 100%;
  bottom: 20px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
}

.scrollTop:hover {
  opacity: 1;
}

.underline {
  text-decoration: underline;
}

.btn {
  line-height: 1 !important;
  text-transform: none !important;
}
.nav-link {
  font-size: 1.15rem !important;
  text-transform: none !important;
}

/* .white-bg {
  background-color: #bbc !important;
} */

.click-to-copy:hover {
  cursor: pointer;
  background-color: yellow;
  transition: background-color 0.5s;
}

.click-to-copy {
  background-color: none;
  transition: background-color 0.5s;
  border-bottom: 1px dotted gray;
}

.form-control {
  border-left-style: none !important;
  border-right-style: none !important;
  border-top-style: none !important;
}

.rbt {
  padding-top: 26px !important;
  padding-bottom: 10px !important;
}

.rbt-input {
  border-bottom-style: none !important;
}

.rbt.rbt-tight {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.custom-tooltip {
  background-color: white;
}

.mark-a {
  background-color: #b0e5b0;
}

.mark-b {
  background-color: #bfe9b4;
}
.mark-c {
  background-color: #cfedb8;
}

.mark-d {
  background-color: #def1bc;
}

.mark-e {
  background-color: #eef4c0;
}

.mark-f {
  background-color: #fdf8c4;
}

.mark-blue-a {
  background-color: #9ba9ff;
}

.mark-blue-b {
  background-color: #a5adff;
}

.mark-blue-c {
  background-color: #afb1ff;
}

.mark-blue-d {
  background-color: #b9b5ff;
}

.mark-rush {
  background-color: #00ff00;
}

.black-href {
  color: black;
}

input:placeholder-shown {
  font-style: italic;
  opacity: 0.5;
}

.job-hl-panel2 {
  background-color: rgba(187, 39, 245, 0.8);
}

.job-hl-notes {
  background-color: rgba(39, 245, 226, 0.8);
}

.job-hl-files {
  background-color: rgba(245, 40, 145, 0.8);
}

.job-hl-files a {
  color: white;
}
